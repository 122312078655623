<template>
  <div class="add-enterprise">
    <head-bar class="fixed-head" type="primary" :title="'我要参展'" @onClickLeft="goBack" />
    <van-dialog
      v-model="reasonShow"
      :confirm-button-color="$store.state.themeInfo.themeColor"
      title="原因详情"
    >
     <div v-if="form.reason" class="error-content">
        <p v-for="(item,index) in form.reason.reasonList" :key="index" class="t-error">
          {{ index + 1 + '. ' + item }}
          <span v-if="form.reason.remarks[index] && form.reason.remarks[index].value">{{'('+form.reason.remarks[index].value+')'}} </span>
        </p>
        <p v-if="form.reason.otherReason" class="t-error">{{form.reason.reasonList.length + 1 + '. ' + form.reason.otherReason}}</p>
     </div>
    </van-dialog>
    <div v-if="form.type && !previewShow" class="results">
      <span>审核状态: </span>
      <div>
        <span v-if="form.type===2 && form.secType === 2" class="t-success">审核通过，请在企业展厅查看</span>
        <span v-else-if="form.type===3 || form.secType === 3" class="t-error">审核未通过 <a :style="{ color: $store.state.themeInfo.themeColor, textDecoration: 'underline' }" @click.prevent="reasonShow = true"> 查看原因</a></span>
        <span v-else>审核中</span>
        <!-- <span v-if="form.type===3 && form.reason" class="t-error">
          (<span v-for="(item,index) in form.reason" :key="index">
            {{item}}
          </span>)  
        </span> -->
      </div>
    </div>
    <van-button v-if="!previewShow" class="btn_download" round type="info" @click="donwFile" :style="{ background: $store.state.themeInfo.themeColor, border: 'none' }">授权函模板下载</van-button>
    <van-form v-if="!isSend && !previewShow">
      <van-field
        class="input-field input_require"
        v-model="form.name"
        label="企业名称"
        :maxlength="100"
        :rules="[{ required: true, message: '请填写企业名称', trigger: 'onChange' }]"
        placeholder="请填写企业名称"
      >
        <template #label>
          <div>企业名称</div>
          <div class="tips">(必 填)</div>
        </template>
      </van-field>

      <van-field
        class="input-field input_require"
        v-model="form.industry"
        label="产业分类"
        readonly
        :rules="[{ required: true, message: '请选择产业分类', trigger: 'onChange' }]"
        placeholder="请选择产业分类"
        @click="groupShow=true"
      >
       <template #label>
          <div>产业分类</div>
          <div class="tips">(必 填)</div>
        </template>
      </van-field>

      <van-action-sheet
        v-model="groupShow"
        :actions="enterpriseOption"
        @select="selectEnterprise"
      >
      </van-action-sheet>

      <van-field
        class="input-field input_require"
        v-model="form.briefSynopsis"
        label="企业简介"
        type="textarea"
        :maxlength="300"
        :rules="[{ required: true, message: '请填写企业简介', trigger: 'onChange' }]"
        placeholder="请填写企业简介"
      >
      <template #label>
          <div>企业简介</div>
          <div class="tips">(必 填)</div>
        </template>
      </van-field>

      <div class="bg_block"></div>
      
      <van-field
        class="input-field input_require"
        v-model="form.scale"
        label="公司规模 "
        type="textarea"
        readonly
        :rules="[{ required: true, message: '请选择公司规模', trigger: 'onChange' }]"
        placeholder="请选择公司规模"
        @click="scaleShow=true"
      >
        <template #label>
          <div>公司规模 </div>
          <div class="tips">(必 填)</div>
        </template>
      </van-field>

      <van-action-sheet
        v-model="scaleShow"
        :actions="scaleList"
        @select="selectScale"
      >
      </van-action-sheet>

      <van-field
        class="input-field input_require"
        v-model="form.financing"
        label="融资情况"
        readonly
        type="textarea"
        :rules="[{ required: true, message: '请选择融资情况', trigger: 'onChange' }]"
        placeholder="请选择融资情况"
        @click="financingShow=true"
      >
      <template #label>
          <div>融资情况 </div>
          <div class="tips">(必 填)</div>
        </template>
      </van-field>

      <van-action-sheet
        v-model="financingShow"
        :actions="financingList"
        @select="selectfinancing"
      >
      </van-action-sheet>

      <van-field
        class="input-field input_require"
        v-model="form.address"
        label="公司地址 "
        type="textarea"
        :maxlength="200"
        :rules="[{ required: true, message: '请填写公司地址', trigger: 'onChange' }]"
        placeholder="请填写公司地址 "
      >
      <template #label>
          <div>公司地址 </div>
          <div class="tips">(必 填)</div>
        </template>
      </van-field>

      <van-field
        class="input-field input_require"
        v-model="form.recruitmentLink"
        label="官网链接 "
        type="textarea"
        :maxlength="200"
        :rules="[{ required: true, message: '请填写官网链接', trigger: 'onChange' }, { pattern: /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/, message: '请输入正确的官网链接'  }]"
        placeholder="请填写官网链接 "
      >
      <template #label>
          <div>官网链接 </div>
          <div class="tips">(必 填)</div>
        </template>
      </van-field>

      <van-field
        class="input-field img-field input_require"
        label="企业logo"
        :rules="[{ required: true, message: '请上传企业logo' }]"
      >
        <template #input>
          <van-uploader v-model="logoFileList" :max-count="1" :before-read="beforeRead"  :after-read="uploadLogo"  :preview-full-image="false" />
        </template>
         <template #label>
          <div>企业logo</div>
          <div class="tips">(必 填)</div>
          <div class="size">(建议尺寸为710px*360px)</div>
        </template>
      </van-field>

      <van-field
        class="input-field img-field input_require"
        label="授权函图片"
        :rules="[{ required: true, message: '请上传授权函图片' }]"
      >
        <template #input>
          <van-uploader v-model="authorizationImgList" :max-count="1" :before-read="beforeRead"  :after-read="uploadAuthorizationImg"  :preview-full-image="false" />
        </template>
         <template #label>
          <div>授权函图片</div>
          <div class="tips">(必 填)</div>
        </template>
      </van-field>

      <van-field
        class="input-field img-field banner_field input_require"
      >
      <template #label>
          <div>banner图</div>
          <div class="size">(建议尺寸为710px*360px)</div>
      </template>
        <template #input>
          <van-uploader result-type="file" v-model="bannerFileList" :max-count="3" :before-read="beforeRead"  :after-read="uploadBanner"  :preview-full-image="false" />
          <div class="tip">建议上传官方图片、产品图片等</div>
        </template>
      </van-field>

      <van-field
        class="input-field img-field banner_field input_require"
      >
        <template #label>
          <div>视频介绍</div>
          <div class="size">(建议视频大小不超过100MB)</div>
        </template>
        <template #input>
          <van-uploader v-model="videoFileList" :max-count="1" :before-read="beforeReadVideo" :max-size="1000 * 1024 * 100" accept="video/*" :after-read="uploadVideo" @oversize="onOversize">
          </van-uploader>
        </template>
      </van-field>

      <!-- 招聘岗位 -->
      <!-- <div class="bg_block"></div>

      <div class="job-wrapper">
        <div class="job-head">招聘岗位</div>

        <div class="job-list">
          <div v-for="(item, index) in form.positions" :key="item.id" class="job-item">
            <div class="item-head">
              <span>岗位{{index+1}}</span>
              <van-icon class="close" name="close" @click="handleRemoveJob(index)" />
            </div>
            <van-field
              class="input-field job-field"
              label="岗位名称"
              :maxlength="30"
              v-model="item.name"
            >
            </van-field>

            <van-field
              class="input-field job-field"
              label="职位类型"
              :maxlength="20"
              v-model="item.type"
            >
            </van-field>

            <van-field
              class="input-field job-field"
              label="薪酬待遇"
              :maxlength="40"
              v-model="item.salary"
            >
            </van-field>

            <van-field
              class="input-field job-field"
              label="经验要求"
              :maxlength="40"
              v-model="item.experience"
            >
            </van-field>

            <van-field
              class="input-field job-field"
              label="工作地点"
              :maxlength="100"
              v-model="item.address"
            >
            </van-field>

            <van-field
              class="input-field job-field"
              label="最低学历要求"
              :maxlength="20"
              v-model="item.degree"
            >
            </van-field>

            <van-field
              class="input-field job-field"
              label="职位描述"
              type="textarea"
              placeholder="200字以内"
              :maxlength="200"
              v-model="item.description"
            >
            </van-field>

            <van-field
              class="input-field job-field"
              label="任职要求"
              type="textarea"
              placeholder="200字以内"
              :maxlength="200"
              v-model="item.jobRequirements"
            >
            </van-field>

          </div>
        </div>

        <div class="btn-wrap">
          <van-button native-type="button" @click="handleAddJob" class="btn_add" round type="info" icon="plus" :style="{ background: $store.state.themeInfo.themeColor, border: 'none' }">
            添加招聘岗位
          </van-button>
        </div>
      </div> -->

      <div class="preview" v-if="enterprise.logo">
        <div @click="preview">预览</div>
      </div>
      <div style="margin: 0 15px;">
        <van-button :disabled="loading" :loading="loading" round block type="info" @click="onSubmit"  :style="{ background: $store.state.themeInfo.themeColor, border: 'none' }">
          提交
        </van-button>
      </div>
    </van-form>
    <enterprise-details v-if="previewShow" :preview="true" :enterprise="enterprise" />
    <!-- <van-skeleton style="padding-top:15px" v-else title :row="4" /> -->
  </div>
</template>

<script>
import headBar from '@/components/headBar/headBar'
import enterpriseDetails from './enterprisDetails'
import { Toast, Dialog } from 'vant'
export default {
  name: 'addEnterprise',
  props: {
    phone: {
      type: String
    }
  },
  components: { headBar, enterpriseDetails },
  data() {
    return {
      form: {
        name: '',
        briefSynopsis: '',
        recruitmentLink: '',
        logo: '',
        videoUrl: '',
        industry: '',
        positions: [],
        scale: '',
        financing: '',
        address: '',
        authorizationImg: ''
      },
      bannnerList: [],
      logoFileList: [],
      bannerFileList: [],
      videoFileList: [],
      authorizationImgList: [],
      loading: false,
      companyList: [],
      isSend: false,
      previewShow: false,
      enterpriseOption: [
        { value: '移动互联网', name: '移动互联网', checked: false },
        { value: '软件及信息服务', name: '软件及信息服务', checked: false },
        { value: '5G应用', name: '5G应用', checked: false },
        { value: '大数据', name: '大数据', checked: false },
        { value: '人工智能及机器人', name: '人工智能及机器人', checked: false },
        { value: '新一代半导体及集成电路', name: '新一代半导体及集成电路', checked: false },
        { value: '自主可控及信息安全', name: '自主可控及信息安全', checked: false },
        { value: '先进制造业', name: '先进制造业', checked: false },
        { value: '新能源与节能环保', name: '新能源与节能环保', checked: false },
        { value: '生物医药', name: '生物医药', checked: false },
        { value: '新材料', name: '新材料', checked: false }
      ],
      groupShow: false,
      // 公司规模字段
      scaleList: [
        { value: '1000人以下', name: '1000人以下' },
        { value: '1000人-2000人', name: '1000人-2000人' },
        { value: '2000人-5000人', name: '2000人-5000人' },
        { value: '5000人-10000人', name: '5000人-10000人' },
        { value: '10000人', name: '10000人' }
      ], 
      financingList: [
        { value: '已上市', name: '已上市' },
        { value: 'IPO', name: 'IPO' },
        { value: '未上市', name: '未上市' },
        { value: '初创', name: '初创' },
      ],
      scaleShow: false,
      financingShow: false,
      reasonShow: false
    }
  },
  computed: {
    enterprise() {
      const enterprise = {}
      enterprise.name = this.form.name
      enterprise.briefSynopsis = this.form.briefSynopsis
      enterprise.recruitmentLink = this.form.recruitmentLink
      enterprise.logo = this.form.logo
      enterprise.videoUrl = this.form.videoUrl
      enterprise.bannerList = [...this.bannerFileList]
      if (this.form.logo) {
        enterprise.bannerList.unshift({ url: this.form.logo })
      }
      enterprise.bannerList = JSON.stringify(this.bannerFileList)
      return enterprise
    },
    selectedGroup() {
      let group = []
      this.enterpriseOption.forEach(item => {
        if (item.checked) {
          group.push(item.value)
        }
      })
      // return group.join(',')
      return group
    },
    groupString() {
      return this.selectedGroup.join('|')
    },
  },
  watch: {
    logoFileList(val) {
      if(val.length > 0) {
        this.form.logo = val[0].url
      } else {
        this.form.logo = ''
      }
    },
    authorizationImgList(val) {
      if(val.length > 0) {
        this.form.authorizationImg = val[0].url
      } else {
        this.form.authorizationImg = ''
      }
    },
    bannerFileList(val) {
      console.log(val)
    }
  },
  mounted() {
    Dialog.alert({
      title: '',
      message: '我方于上传资料前同意以下内容：上传资料的真实性、合法性等均由我方负责，如因上传资料被发现存在不真实、不合法或侵犯他方权利等的情形，由我方承担由此产生的赔偿责任及其他法律责任。',
    })
    this.getList()
      Toast({
            message:'参展申请已结束，感谢您的关注。',
            zIndex:9999,
            mask:true,
            forbidClick:true,
            duration:0
      })
  },
  methods: {
    donwFile() {
      window.location.href = '/api/api/download/授权文件模板.docx'
    },
    handleAddJob() {
      this.form.positions.push({
        name: '',
        type: '',
        salary: '',
        experience: '',
        address: '',
        degree: '',
        description: '',
        jobRequirements: ''
      })
    },

    handleRemoveJob(i) {
      this.form.positions.splice(i, 1)
    },

    getList() {
      if (!this.$store.state.userInfo.openid) {
        return
      }
      this.isSend = true
      const data = {
        createBy: this.$store.state.userInfo.openid
      }
      this.$http.post(this.$baseURL.index.getCompanyList(), data, 1).then(res => {
        this.isSend = false
        this.companyList = res.data
        if (this.companyList.length > 0) {
          this.form.name = this.companyList[0].name
          this.form.briefSynopsis = this.companyList[0].briefSynopsis
          this.form.recruitmentLink = this.companyList[0].recruitmentLink
          this.form.logo = this.companyList[0].logo
          this.form.authorizationImg = this.companyList[0].authorizationImg || ''
          this.form.type = this.companyList[0].type
          this.form.secType = this.companyList[0].secType
          
          this.form.id = this.companyList[0].id
          this.logoFileList = [
            {
              url: this.form.logo
            }
          ]

          if (this.form.authorizationImg) {
            this.authorizationImgList = [
              {
                url: this.form.authorizationImg
              }
            ]
          }
         

          if (this.companyList[0].videoUrl) {
            this.form.videoUrl = this.companyList[0].videoUrl
            this.videoFileList = [
              {
                url: this.form.videoUrl
              }
            ]
          }

          if (this.companyList[0].reason) {
            this.form.reason = JSON.parse(this.companyList[0].reason)
          }

          this.form.industry = this.companyList[0].industry
          this.form.scale = this.companyList[0].scale
          this.form.financing = this.companyList[0].financing
          this.form.address = this.companyList[0].address
          this.bannerFileList = JSON.parse(this.companyList[0].bannerList)
          if (this.companyList[0].positionList) {
            this.form.positions = this.companyList[0].positionList
          }
         
        }
        
      })
    },
    preview() {
      this.previewShow = true
    },

    goBack() {
      if (this.previewShow) {
        this.previewShow = false
        return
      }
      this.$parent.showOne = 1
    },

    beforeRead(file) {
      if (file.type !== 'image/jpeg' && file.type !== 'image/jpg' && file.type !== 'image/png') {
        Toast('文件类型不支持');
        return false;
      }
      return true;
    },
    beforeReadVideo(file) {
      console.log(file)
      if (file.type !== 'video/x-matroska' && file.type !== 'video/mp4' && file.type !== 'video/AVI') {
        Toast('文件类型不支持');
        return false;
      }
      return true;
    },

    async uploadLogo(file) {
      file.status = 'uploading'
      file.message = '上传中...'

      const data = new FormData()
      data.append('file', file.file)
      data.append('isFace', 0)
      let result = await this.$http.post(this.$baseURL.index.uploadImg(), data, 1)
      if (result.data) {
        this.form.logo = result.data
        file.status = 'success'
        file.message = '上传成功'
        file.url = result.data
      }

    },

    async uploadAuthorizationImg(file) {
      file.status = 'uploading'
      file.message = '上传中...'

      const data = new FormData()
      data.append('file', file.file)
      data.append('isFace', 0)
      let result = await this.$http.post(this.$baseURL.index.uploadImg(), data, 1)
      if (result.data) {
        this.form.authorizationImg = result.data
        file.status = 'success'
        file.message = '上传成功'
        file.url = result.data
      }
    },

    async uploadBanner(file) {
      file.status = 'uploading'
      file.message = '上传中...'
      // 只允许mp4格式
      const data = new FormData()
      data.append('file', file.file)
      data.append('isFace', 0)
      let result = await this.$http.post(this.$baseURL.index.uploadImg(), data, 1)
      console.log(result.aaa)
      if (result.data) {
        file.status = 'success'
        file.message = '上传成功'
        file.url = result.data
      }
      
    },

    uploadVideo(file) {
      file.url = file.content
    },
    onOversize() {
      Toast('文件大小不能超过 100MB');
    },

    groupSelect() {
    },

    selectEnterprise(item) {
      this.form.industry = item.value
      this.groupShow = false
    },
    selectScale(item) {
      this.form.scale = item.value
      this.scaleShow = false
    },
    selectfinancing(item) {
      this.form.financing = item.value
      this.financingShow = false
    },

    onSubmit() {
      if (!this.form.name) {
        Toast('请填写企业名称！')
        return
      }
      if (!this.form.industry) {
        Toast('请选择企业分类！')
        return
      }
      if (!this.form.briefSynopsis) {
        Toast('请填写企业简介！')
        return
      }
      if (!this.form.scale) {
        Toast('请选择公司规模！')
        return
      }
      if (!this.form.financing) {
        Toast('请选择融资情况！')
        return
      }
      if (!this.form.address) {
        Toast('请填写公司地址！')
        return
      }
      if (!this.form.recruitmentLink) {
        Toast('请填写官网链接！')
        return
      }
      if (!/^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/.test(this.form.recruitmentLink)) {
        Toast('官网链接格式不正确，请重新填写！');
        return;
      }
      if (!this.form.logo) {
        Toast('请上传企业logo！')
        return
      }
      if (!this.form.authorizationImg) {
        Toast('请上传授权函图片！')
        return
      }
      
      this.loading = true
      const bannerList = []

      this.bannerFileList.forEach(item =>  {
        item.url && bannerList.push({
          url: item.url,
          link: '',
          linkType: 1
        })
      })
      const data = new FormData()
      data.append('name', this.form.name)
      data.append('briefSynopsis', this.form.briefSynopsis)
      data.append('logo', this.form.logo)
      data.append('authorizationImg', this.form.authorizationImg)
      data.append('recruitmentLink', this.form.recruitmentLink)
      data.append('bannerList', JSON.stringify(bannerList))
      data.append('industry', this.form.industry)
      data.append('scale', this.form.scale)
      data.append('financing', this.form.financing)
      data.append('address', this.form.address)
      data.append('phone', this.phone)
      if (this.form.positions.length > 0) {
        data.append('havePosition', 1)
        this.form.positions.forEach((item, index) => {
          for(let key in item) {
            data.append(`positions[${index}].${key}`, item[key])
          }
        })
      } else {
        data.append('havePosition', 0)
      }
    
      if (this.videoFileList.length > 0) {
        if (!this.videoFileList[0].url.startsWith('http')) {
          Toast('上传文件中')
          data.append('file', this.videoFileList[0].file)
        } else {
          data.append('videoUrl', this.form.videoUrl)
        }
      } else {
        data.append('videoUrl', '')
      }
      
      if (this.companyList.length > 0) {
        if(this.form.type===2){
          Dialog.confirm({
            title:'温馨提示',
            message:'您好！当前企业信息已审核通过并展示在创新馆，如需修改内容将重新审核，审核通过后方可展示在创新馆页面。'
          }).then(()=>{
            data.append('id', this.form.id)
            data.append('updateBy', this.$store.state.userInfo.openid)
            this.$http.post(this.$baseURL.index.updateCompany(), data, 1).then((res) => {
              if(res.code===200) {
                this.getList()
                Dialog.alert({
                  title: '提示',
                  message: '您的信息已提交，请等待组委会工作人员审核，审核通过后将在企业展厅发布。',
                })
              } else {
                Toast(res.message)
              }
            
              this.loading = false
            })
          }).catch(()=>{
            this.loading = false
          })  
        }else{
            data.append('id', this.form.id)
            data.append('updateBy', this.$store.state.userInfo.openid)
            this.$http.post(this.$baseURL.index.updateCompany(), data, 1).then((res) => {
              if(res.code===200) {
                this.getList()
                Dialog.alert({
                  title: '提示',
                  message: '您的信息已提交，请等待组委会工作人员审核，审核通过后将在企业展厅发布。',
                })
              } else {
                Toast(res.message)
              }
            
              this.loading = false
            })
        }
        
        
      } else {
        data.append('createBy', this.$store.state.userInfo.openid)
        data.append('openId', this.$store.state.userInfo.openid)
        this.$http.post(this.$baseURL.index.addCompany(), data, 1).then((res) => {
          if (res.code===200) {
            this.getList()
            Dialog.alert({
              title: '提示',
              message: '您的信息已提交，请等待组委会工作人员审核，审核通过后将在企业展厅发布。',
            })
          } else {
            Toast(res.message)
          }
        
          this.loading = false
        })
      }

    },

  }
}
</script>

<style lang="less" scoped>
.add-enterprise {
  height: 100%;
  padding-top: 50px;
  overflow-y: auto;
}

// /deep/.van-field{
//   &::before{
//     content: ''
//   }
// }
/deep/.van-field__label{
  position: relative;
  color: #222;
  font-size: 13px;
  font-weight: bold;
  margin-right: 0.8rem;
  width: 70px;
  display: flex;
  justify-content: space-between;
}
/deep/.van-field__control{
  &::-webkit-input-placeholder{
    color: #999;
    font-size: 13px;
  }
}

/deep/.img-field {
  .van-uploader {
    width: 200px;
    min-height: 100px;
    .van-uploader__upload{
      width: 200px;
      height: 100px;
      margin-right: 0;
    }
    .van-uploader__preview-image, .van-uploader__file {
      width: 200px;
      height: 100px;
    }
    img {
      width: 200px;
      height: 100px;
    }
  }

}

.preview{
  display: flex;
  justify-content: flex-end;
  margin: 15px;
  div {
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    color: #fff;
    font-size: 12px;
    text-align: center;
    background: rgb(25, 41, 136);
  }
 
}

.van-divider{
  margin: 0;
}
.input-field{
  &::after{
    position: absolute;
    display: inline-block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    content: '' !important;
    pointer-events: none;
    right: 0.42667rem;
    bottom: 0;
    left: 0.42667rem;
    border-bottom: 0 solid #ebedf0 !important;
    -webkit-transform: scaleY(.5);
    -ms-transform: scaleY(.5);
    transform: scaleY(.5);
  }
}

.preview-cover {
  position: absolute;
  bottom: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  color: #fff;
  font-size: 12px;
  text-align: center;
  z-index: 1;
}

.bg_block {
  width: 100%;
  height: 10px;
  background: #f2f8fc;
}

video {
  width: 100%;
  height: 100%;
}

.banner_field {
  /deep/ .van-field__control--custom {
    flex-wrap: wrap;
    .tip {
      color: #969799;
      font-size: 12px;
    }
  }
}

.input_require {
  /deep/ .van-field__label {
    display: initial;
    .tips{
      width: 100%;
      font-size: 10px;
      font-weight: normal;
      color: #666;
      // span {
      //   display:inline-block;
      //   width:100%;
      // }
    }
    .size {
      font-weight: normal;
      font-size: 10px;
      color: #666;
    }
  }
}

.job-wrapper {
  background: #fff;
  padding: 0 15px;
  .job-head {
    height: 50px;
    line-height: 50px;
    font-size: 14px;
    color: #333;
    font-weight: bold;
  }
  .job-list {
    .job-item {
      margin-bottom: 10px;
      padding: 0 10px 6px;
      border: 1px solid #e2e2e2;
      border-radius: 2px;
      .item-head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 42px;
        line-height: 42px;
        font-size: 14px;
        color: #333;
        span {
          font-weight: bold;
        }
        .close {
          font-size: 30px;
          color: #ee0a24;
        }
      }

      .job-field {
        margin-bottom: 10px;
        padding-left: 10px;
        border-radius: 2px;
        border: 1px solid #ccc;
        /deep/ .van-field__label{
          min-width: 70px;
          width: auto;
          color: #747474;
          margin-right: 10px;
        }
      }


    }
  }

  .btn-wrap {
    margin: 20px 0 50px;
    display: flex;
    justify-content: center;
    .btn_add {
      width: 140px;
      height: 34px;
      font-size: 12px;
    }
  }
}

.results {
  padding: 10px 15px 0;
  text-align: left;
  font-size: 14px;
  display: flex;
  .t-success {
    color: #52c41a;
  }
  .t-error {
    color: #fa541c;
  }
  &>div {
    flex: 1;
  }
}
.error-content{
  padding: 15px;
  .t-error {
    color: #fa541c;
    margin: 5px 0;
    font-size: 13px;
  }
}

.fixed-head {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.btn_download {
  margin-left: 15px;
  width: 140px;
  height: 34px;
  font-size: 12px;
}
</style>