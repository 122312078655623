<template>
  <div :style="{ '--themeColor': $store.state.themeInfo.themeColor, height: '100%', background: '#fff' }" @click="typeShow = false">
    <transition name="list">
      <div v-show="showOne===1">
        <van-sticky type="primary">
          <head-bar :title="'企业展厅'" @onClickLeft="onClickLeft" />
        </van-sticky>
        <div class="banner-wraper">
          <div class="banner">
            <!-- <img class="bg" :src="require('@/assets/images/banner.png')" alt=""> -->
            <div class="bg">
              <Banner :active="false"/>
            </div>
            <div class="banner-text">
              <!-- 企业展厅 -->
              <div class="search-wraper">
                <van-search placeholder="企业名称" @search="search" v-model="keyword" show-action>
                  <template #action>
                    <div style="color:#999" @click="search">搜索</div>
                  </template>
                </van-search>
                <div class="type-btn" @click.stop="typeShow = !typeShow">
                  <span :class="{ nameActived: enterpriseType ? true : false }">产业类别</span>
                  <van-icon :style="{ marginLeft: '5px', transition: '.3s', transform: typeShow ? 'rotate(90deg)' : '', position: 'absolute', right: '5px' }" name="arrow" />
                </div>
              </div>
              <div :class="{'type-container': true, 'type-act': typeShow}">
                <van-tag
                  :class="{ tag: true, 'tag-act': item.checked }"
                  size="medium"
                  v-for="item in enterpriseOption"
                  :key="item.value"
                  @click.stop="changeType(item)"
                >
                  {{ item.text }}
                </van-tag>

              </div>
            </div>
          </div>
        </div>
        <div class="enterprise-container" ref="container" @click.stop="typeShow = false">
          <van-list v-model="listLoading" :finished="finished" :offset="50" :finished-text="finishedText" @load="loadList">
            <div class="enterprise-item" v-for="(item, index) in enterpriseList" :key="index" @click="showDetails(item)">
              <div class="logo-wraper">
                <img :src="item.logo+'?imageView2/2/w/375'" alt="">
                <div class="bottom-box">
                  <van-notice-bar :scrollable="false" :text="item.name" />
                </div>
              </div>
            </div>
          </van-list>
        </div>
        <div class="center-vertical" v-if="loading">
          <van-loading></van-loading>
        </div>
        <van-empty description="暂无数据" v-if="!listLoading && enterpriseList.length === 0"></van-empty>
      </div>
    </transition>
    <transition name="details">
      <enterprise-details v-if="showOne===2" :enterprise="enterpriseItem" />
      <addEnterprise v-if="showOne===3" :phone="phone" />
    </transition>

    <!-- 我要参展 -->
    <div v-if="showOne===1" class="float-btn fl-right" :style="{ right: right, bottom: bottom }" @click="handleAddEnterprise">
      <div class="wrap">
        我要参展
        <img :src="require('@/assets/images/icon/icon_right.png')" alt="">
      </div>
    </div>

    <van-overlay :show="loginshow" @click="loginshow = false" class="login-bg">
      <div class="login-wrapper" @click.stop>
        <van-icon class="login-close" name="close" @click="handleCloseLogin" />
        <Login @loginSuccess="loginSuccess" />
      </div>
    </van-overlay>

  </div>
</template>

<script>
import headBar from '@/components/headBar/headBar'
import Banner from '@/components/banner/index'
import enterpriseDetails from './components/enterprisDetails'
import addEnterprise from './components/addEnterprise'
import Login from './components/login'
import { Toast } from 'vant'

export default {
  name: 'enterprise',
  components: { enterpriseDetails, Banner, addEnterprise, Login, headBar },
  data() {
    return {
      enterpriseList: [],
      showOne: 1, // 1主页 2详情 3我要参展
      enterpriseItem: {},
      loading: false,
      showPopover: false,
      actions: [{ text: '移动互联网' }, { text: '半导体及集成电路' }, { text: '人工智能' }, { text: '大数据' }, { text: '5G应用' }, { text: '自主可控及信息安全' }],
      keyword: '',
      right: '0px',
      bottom: '80px',
      enterpriseType: '',
      enterpriseOption: [
        { value: '', text: '全部', checked: true },
        { value: '移动互联网', text: '移动互联网', checked: false },
        { value: '软件及信息服务', text: '软件及信息服务', checked: false },
        { value: '5G应用', text: '5G应用', checked: false },
        { value: '大数据', text: '大数据', checked: false },
        { value: '人工智能及机器人', text: '人工智能及机器人', checked: false },
        { value: '新一代半导体及集成电路', text: '新一代半导体及集成电路', checked: false },
        { value: '自主可控及信息安全', text: '自主可控及信息安全', checked: false },
        { value: '先进制造业', text: '先进制造业', checked: false },
        { value: '新能源与节能环保', text: '新能源与节能环保', checked: false },
        { value: '生物医药', text: '生物医药', checked: false },
        { value: '新材料', text: '新材料', checked: false }
      ],
      typeShow: false,
      listLoading: false,
      finished: false,
      pageIndex: 1,
      loginshow: false,
      openIdExist: false, //openid是否绑定
      phone: ''
    }
  },
  computed: {
    finishedText() {
      if (this.finished && this.enterpriseList.length > 0) {
        return '没有更多了'
      } else {
        return ''
      }
    }
  },
  watch: {
    typeShow(newval, oldval) {
      if (!newval && oldval) {
        this.pageIndex = 1
        this.enterpriseList = []
        this.finished = this.listLoading = false
        this.getList('', true)
      }
    }
  },
  mounted() {
    // this.getList()
    this.getOpenIdExist()
    // 获取是不是手机端
    const isMobile = window.innerWidth <= 768; // 可根据实际情况调整判断阈值
    if (!isMobile) {
        Toast({
        message:'为了更好的网站显示效果，请您将浏览器页面尺寸调整为手机尺寸，即手机页面的竖状显示。',
      })
    } 
    document.querySelector('.banner-wraper').addEventListener('wheel',this.bannerScroll)
  },
  // beforeRouteLeave(to, from, next) {
  //   // 涉及到跳转 直接显示敬请期待
  //   Toast('敬请期待')
  // },
  beforeDestroy() {
    document.querySelector('.banner-wraper').removeEventListener('wheel',this.bannerScroll)
  },
  methods: {
    bannerScroll(event){
       event.preventDefault();
      document.querySelector('.enterprise-container').scrollTop +=event.deltaY
    },
    loadList() {
      this.getList('', true)
    },
    onClickLeft() {
      this.$parent.onChange(0)
    },
    showDetails(item) {
      // console.log(item)
      this.showOne = 2
      this.enterpriseItem = item
    },
    async getList(keyword = null, isLoading = false) {
      const data = {
        keyword: keyword,
        pageIndex: isLoading ? this.pageIndex : 1,
        pageSize: 10,
        industry: this.enterpriseType ? this.enterpriseType.split(',').join('|') : '',
        type: 2,
        secType: 2,
        auditAdmin: false,
        pageSorts: [
          {
            column: 'serialNumber',
            asc: true
          }
        ]
      }
      this.listLoading = true
      const result = await this.$http.post(this.$baseURL.index.getEnterprise(), data, 1)
      this.loading = this.listLoading = false
      if (!isLoading) {
        this.enterpriseList = result.data.records
      } else {
        result.data.records.forEach(item => {
          this.enterpriseList.push(item)
        })
        this.pageIndex ++
        if (this.enterpriseList.length === result.data.total || result.data.records.length === 0) {
          this.finished = true
        }
      }
    },
    search(value) {
      this.pageIndex = 1
      this.enterpriseList = []
      this.finished = this.listLoading = false
      this.getList(typeof (value) === 'string' ? value : this.keyword, true)
    },
    changeType(item) {
      if (item.value !== '' || (item.value === '' && item.checked === false)) {
        item.checked = !item.checked
      }
      if (item.checked && item.value === '') {
        this.enterpriseOption.forEach(i => {
          if (i.value !== '') i.checked = false
        })
      } else {
        this.enterpriseOption[0].checked = false
      }
      let str = []
      this.enterpriseOption.forEach(i => {
        if (i.checked && i.value !== '') {
          str.push(i.text)
        }
      })
      if (str.length === 0) {
        this.enterpriseOption[0].checked = true
      }
      this.enterpriseType = str.join(',')
    },
    initFloatMenu() {
      this.$nextTick(() => {
        const menu = document.querySelector('.float-menu')
        const mh = parseFloat(getComputedStyle(menu).height)
        const mw = parseFloat(getComputedStyle(menu).width)
        const screenWidth = parseFloat(getComputedStyle(document.documentElement).width)
        const screenHeight = parseFloat(window.screen.availHeight)
        this.left = `calc(100% - ${mw}px)`
        menu.addEventListener('touchmove', e => {
          e.preventDefault()
          menu.style.left = e.touches[0].clientX - mw / 2 + 'px'
          menu.style.top = e.touches[0].clientY - mh / 2 + 'px'
          menu.style.transition = '0s'
        }, { passive: false })
        menu.addEventListener('touchstart', () => {
          menu.classList.remove('fl-right')
          menu.classList.remove('fl-left')
        }, { passive: false })
        menu.addEventListener('touchend', () => {
          menu.style.transition = '.3s'
          if (screenWidth - parseFloat(getComputedStyle(menu).left) - mw / 2 > screenWidth / 2) {
            menu.style.left = '0px'
            menu.classList.add('fl-left')
          } else {
            menu.style.left = `calc(100% - ${mw}px)`
            menu.classList.add('fl-right')
          }
          if (parseFloat(getComputedStyle(menu).top) < 0 || parseFloat(getComputedStyle(menu).top) + mh > screenHeight) {
            menu.style.top = parseFloat(getComputedStyle(menu).top) < 0 ? '0px' : screenHeight - mh + 'px'
          }
        }, { passive: false })
      })
    },
    handleAddEnterprise() {
      Toast('参展申请已结束，感谢您的关注。')
      // if (!this.openIdExist) {
      //   this.loginshow = true
      // } else {
      //   this.showOne = 3
      // }
    },
    handleCloseLogin() {
      this.loginshow = false
    },
    // 查询openId是否绑定
    async getOpenIdExist() {
      const data = {
        openId: this.$store.state.userInfo.openid
      }
      const result = await this.$http.postParams(this.$baseURL.index.openIdExist(), data, 1)
      if (result.data) {
        this.openIdExist = true
        this.phone = result.data
      }
    },
    loginSuccess(data) {
      this.phone = data
      this.loginshow = false
      this.openIdExist = true
      this.showOne = 3
    }
  }
}
</script>

<style lang="less" scoped>
@themeColor: var(--themeColor);
.banner-wraper{
  .banner{
    position: relative;
    display: flex;
    justify-content: center;
    height: 4.8rem;
    margin-bottom: 0.9rem;
    .bg{
      height: 100%;
      width: 100%;
    }
    .banner-text{
      padding: 15px;
      position: absolute;
      display: flex;
      align-items: center;
      flex-direction: column;
      bottom: -0.9rem;
      width: 100%;
      background: #fff;
      backdrop-filter: blur(10px);
      font-size: 15px;
      font-weight: bold;
      box-shadow: 0 0 5px 0 rgba(0,0,0,.05);
      border-top-right-radius: 12px;
      border-top-left-radius: 12px;
      z-index: 5;
      .type-container{
        width: 100%;
        position: absolute;
        top: 100%;
        left: 0;
        background: #fff;
        padding: 15px;
        transition: .3s;
        box-shadow: 0 5px 3px 0px rgba(0,0,0,.05);
        transform: rotateX(90deg);
        opacity: 0;
        transform-origin: top;
        border-top: 0.001rem solid #eee;
        .tag{
          font-size: 15px;
          padding: 4px 6px;
          font-weight: normal;
          margin: 5px;
          color: #888;
          background: #fff;
          border-radius: 4px;
          border: 1px solid #bebebe;
        }
        .tag-act{
          color: #fff;
          background: @themeColor;
          border-color: @themeColor;
        }
      }
      .type-act{
        transform: rotateX(0);
        opacity: 1;
      }
      .van-divider{
        width: 100%;
        margin: 0;
        color: #3E3F53;
        &::after, &::before{
          border: none;
          // height: 4px;
          border-color: @themeColor;
          // border-width: 2px;
        }
      }
      .search-wraper{
        width: 100%;
        // margin-top: 20px;
        display: flex;
        // box-shadow: 0 0 5px 0 rgba(0,0,0,.05);
        height: 35px;
        font-weight: normal;
        .type-btn{
          position: relative;
          padding-right: 20px;
          height: 35px;
          width: 100px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          color: #666;
          span{
            width: 100%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            text-align: center;
          }
          .nameActived{
            font-weight: bold;
            color: @themeColor;
          }
        }
        .van-button__text{
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .van-search{
          padding: 2px 0;
          margin-right: 10px;
          flex: 1;
          box-shadow: 0 0 5px 0 rgba(0,0,0,.05);
          border: 1px solid #DCE4E8;
          border-radius: 5px;
          overflow: hidden;
          /deep/.van-field__control{
            &::-webkit-input-placeholder{
              color: #999;
              font-size: 13px;
            }
          }
        }
        .van-cell{
          background: #fff;
        }
        .van-search__content{
          background: #fff;
        }
      }
    }
  }
}
.enterprise-container{
  padding: 15px;
  // display: flex;
  // flex-wrap: wrap;
  // justify-content: space-between;
  max-height: calc(100vh - 8rem);
  overflow-y: auto;
  .van-list{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  /deep/.van-list__finished-text,
  /deep/.van-list__loading{
    width: 100%;
  }
  .enterprise-item{
    width: 48%;
    height: 3.5rem;
    background: #fff;
    padding: 10px 10px 0 10px;
    border-radius: 5px;
    margin-bottom: 12px;
    box-shadow: 0 0 5px 1px rgba(0,0,0,.1);
    .logo-wraper{
      height: 100%;
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      border-radius: 5px;
      overflow: hidden;
      img{
        height: calc(100% - 30px);
        width: 100%;
        border-radius: 5px;
        object-fit: contain;
      }
      .bottom-box{
        // position: absolute;
        left: 0;
        bottom: 0;
        // background: linear-gradient(to top, @themeColor 5%, rgba(255,255,255,.05));
        z-index: 1;
        height: 30px;
        width: 100%;
        /deep/ .van-notice-bar {
          width: 100%;
          height: 100%;
          background: none;
          font-size: 12px;
          color: #333;
          padding: 0;
        }
        /deep/.van-notice-bar__wrap{
          text-align: center;
          display: flex;
          justify-content: center;
        }
      }
    }
  }
  .loading-box{
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #999;
    span:nth-of-type(1) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.center-vertical{
  height: 4rem;
}
.details-enter-active,
.details-leave-active,
.list-enter-active,
.list-leave-active{
  position: absolute;
  width: 100vw;
  transition: all .4s ease
}
.list-enter,
.list-leave-to{
  opacity: .1;
  transform: translateX(-100%);
}
.details-enter,
.details-leave-to{
  opacity: .1;
  transform: translateX(100%);
}

.float-btn{
  padding: 8px;
  position: fixed;
  // background: rgba(255, 255, 255, .3);
  transition: .3s;
  z-index: 10;
  .wrap{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    height: 40px;
    width: 130px;
    font-size: 14px;
    background-image: linear-gradient(#5737bf  , #2d3ca9);
    border-radius: 20px;
    img {
      margin-left: 10px;
      width: 20px;
    }
  }
}
.fl-left{
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.fl-right{
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
/deep/.van-popover__action{
  width: 200px!important;
}

.login-bg {
  z-index: 2000;
  .login-wrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    width: 345px;
    height: 260px;
    border-radius: 10px;
    padding: 20px 0px;
    overflow: hidden;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-top:40px;
    .login-close {
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }
}
</style>