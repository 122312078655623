<template>
  <div class="detail-container">
    <div class="detail-main">
      <van-sticky v-if="!preview">
        <head-bar type="primary" :title="'企业介绍'" @onClickLeft="goBack" />
      </van-sticky>
      <van-dialog class="dialog-box" v-model="swiperShow" :closeOnClickOverlay="true" :show-confirm-button="false">
        <!-- <img src="https://img01.yzcdn.cn/vant/apple-3.jpg" /> -->
        <!-- 123 -->
        <div class="swiper-container preview-swiper">
          <div class="swiper-wrapper">
            <div v-for="(item, index) in imgList" :key="index" class="swiper-slide">
              <div class="swiper-zoom-container">
                <img style="height:4.8rem; flex:1" :src="item.url" alt="">
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="swiper-container">
          <div class="swiper-wrapper">
            <div v-for="(item, index) in imgList" :key="index" class="swiper-slide">
              <div class="swiper-zoom-container">
                <img style="height:4.8rem; flex:1" :src="item.url" alt="">
              </div>
            </div>
          </div>
        </div> -->
      </van-dialog>
      <template v-if="imgList.length > 0">
        <div class="banner-wraper">
          <div class="swiper-container enterprise-swiper">
            <div class="swiper-wrapper">
              <div v-for="(item, index) in imgList" :key="index" :data-index="index" class="swiper-slide">
                <img :src="item.url" alt="">
              </div>
            </div>
          </div>
          <!-- <van-swipe class="my-swiper" :autoplay="4200" indicator-color="white">
            <van-swipe-item v-for="(item, index) in imgList" :key="index" @click="showSwiper(index)">
              <img :src="item.url" alt="">
            </van-swipe-item>
          </van-swipe> -->
        </div>
        <div class="enterprise-name">
          <div class="wraper">
            <div class="name">{{ enterprise.name }}</div>
            <!-- <div class="time">发布时间: {{ enterprise.updateTime ? enterprise.updateTime.substr(0, 10) : '-' }}</div> -->
            <van-divider></van-divider>
            <div class="desc">
              <p>企业基本情况</p>
              <p>
                {{ enterprise.briefSynopsis }}
              </p>
            </div>
          </div>
        </div>
        <div class="video-wraper" v-if="enterprise.videoUrl">
          <video
            id="upvideo"
            webkit-playsinline="true"
            x5-video-player-type="h5"
            x5-video-orientation="portraint"
            :poster="imgList[0].url"
            :src="enterprise.videoUrl" controls>
          </video>
        </div>
        
        <div v-if="this.enterprise.recruitmentLink" class="float-wraper">
          <div class="box" @click="goWebsite">
            官网直达
            <div class="arrow">
              <van-icon name="arrow" />
            </div>
          </div>
        </div>

        <!-- <div v-if="this.enterprise.csdnLink" class="float-wraper">
          <div class="box" @click="goRecruitWebsite">
            招聘直达
            <div class="arrow">
              <van-icon name="arrow" />
            </div>
          </div>
        </div> -->
      </template>
    
      <van-skeleton style="padding-top:15px" v-else title :row="4" />
    </div>
    <div class="tip">以上资料的真实性、合法性均由企业负责</div>
  </div>
</template>

<script>
import headBar from '@/components/headBar/headBar'
import Swiper from 'swiper/js/swiper.js'
import 'swiper/css/swiper.css'
import { ImagePreview } from 'vant'
export default {
  name: 'enterpriseDetails',
  components: { headBar },
  props: {
    enterprise: {
      type: Object
    },
    preview: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    enterprise: {
      handler(val) {
        if (val.bannerList) {
          this.imgList = JSON.parse(val.bannerList)
        }
        if (val.logo) {
          this.imgList.unshift({ url: val.logo })
        }
      },
      immediate: true
    }
  },
  data() {
    return {
      imgList: [],
      swiperShow: false,
      swiperIndex: 0,
      swiper: null,
      enterpriseSwiper: null
    }
  },
  mounted() {
    this.initSwiper()
  },
  methods: {
    goBack() {
      if (this.preview) {
        this.$parent.showOne = 2
      } else {
        this.$parent.showOne = 1
      }
    },
    goWebsite() {
      if (!this.enterprise.recruitmentLink.startsWith('http')) {
        return
      }
      window.location.href = this.enterprise.recruitmentLink
    },
    goRecruitWebsite() {
      window.location.href = this.enterprise.csdnLink
    },
    showSwiper(index) {
      // this.swiperShow = true
      // this.swiperIndex = index
      // this.$nextTick(() => {
      //   this.swiper = new Swiper('.swiper-container', {
      //     slidesPerView: 1,
      //     spaceBetween: 10,
      //     loop: true,
      //     zoom: true,
      //     initialSlide: index
      //   })
      // })
      const imgList = []
      this.imgList.forEach(item => {
        imgList.push(item.url)
      })
      ImagePreview({
        images: [...imgList],
        startPosition: parseInt(index),
        showIndex: false
      });
    },
    initSwiper() {
      const vm = this
      this.enterpriseSwiper = new Swiper('.enterprise-swiper', {
        loop: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false
        },
        on: {
          click: function () {
            vm.showSwiper(this.clickedSlide.dataset.index)
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.detail-container {
  height: 100%;
  overflow: hidden;
  position: relative;
  padding-bottom: 20px;
  .detail-main {
    position: relative;
    height: 100%;
    overflow-y: auto;
  }
  .tip {
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 12px;
    text-align: center;
    padding-bottom: 4px;
    text-align: center;
    width: 100%;
    background: #fff;
    color: #666;
  }
}
.banner-wraper{
  // height: 5rem;
  padding: 10px;
  border-radius: 5px;
  // overflow: hidden;
  .enterprise-swiper {
    width: 100%;
    height: 100%;
    img {
      height: 4.8rem;
      width: 100%;
      object-fit: contain;
    }
  }
}
.my-swiper{
  .van-swiper-item{
    // height: 4.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  img{
    height: 4.8rem;
    width: 100%;
    object-fit: contain;
  }
}
.dialog-box{
  width: 100%;
  border-radius: 0;
  padding: 0;
  background: transparent;
  overflow: visible;
  .swiper-container{
    overflow: visible;
  }
  .swiper-zoom-container{
    width: 100%;
    height: 4.8rem;
    display: flex;
    overflow: visible;
    img{
      height: 4.8rem;
      width: 100%;
    }
  }
}
.enterprise-name{
  padding: 15px;
  .wraper{
    border-radius: 5px;
    overflow: hidden;
    padding: 10px;
    background: #fff;
    box-shadow: 0 0 5px 0 rgba(0,0,0,.03);
    font-size: 12px;
    color: #999;
    .name{
      color: #333;
      font-size: 17px;
      font-weight: bold;
      margin-bottom: 10px;
    }
    .desc{
      color: #333;
      font-size: 13px;
      line-height: 1.5;
      p:nth-of-type(2){
        text-indent: 20px;
        margin: 0;
      }
    }
  }
}
.video-wraper{
  height: 5.8rem;
  width: 100%;
  padding: 15px;
  video{
    height: 100%;
    width: 100%;
    object-fit: fill;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid #999;
  }
}
.float-wraper{
  padding: 10px;
  text-align: right;
  font-size: 14px;
  .box{
    display: inline-flex;
    align-items: center;
    height: 35px;
    padding: 0 15px;
    // width: 200px;
    background: #3C97ED;
    color: #fff;
    border-radius: 18px;
    box-shadow: 0 0 5px 0 rgba(0,0,0,.03);
    .arrow{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 22px;
      width: 22px;
      margin-left: 10px;
      border-radius: 50%;
      color: #3C97ED;
      background: #fff;
    }
  }
}


/deep/ .van-skeleton__row, .van-skeleton__title{
  background: #dedede;
}
</style>