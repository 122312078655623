<template>
  <div>
      <template>
        <van-form @submit="onSubmit">
          <van-field
            v-model="form.phone"
            required
            label="手机号"
            placeholder="手机号"
            type="digit"
            :error-message="phoneErrorMsg"
            maxlength="11"
            @input="phoneErrorMsg = ''"
            :rules="[{ required: true, message: '请填写正确手机号',pattern : phoneRules, trigger: 'onChange' }]"
          >
            <template #label>
              <span>手</span>
              <span>机</span>
              <span>号</span>
            </template>
          </van-field>
          <van-field
            v-model="form.verifyCode"
            center
            required
            :rules="[{ required: true, message: '请输入验证码', trigger: 'onChange' }]"
            clearable
            label="短信验证码"
            :maxlength="6"
            type="digit"
            placeholder="请输入短信验证码"
          >
            <template #button>
              <van-button
                size="small"
                :style="{ background: $store.state.themeInfo.themeColor, borderRadius: '5px', border: 'none', color: '#fff' }"
                @click="sendCode"
                :disabled="waitCode"
                native-type="button"
              >
                {{ sendCodeText }}
              </van-button>
            </template>
            <template #label>
              <span>短</span><span>信</span><span>验</span><span>证</span><span>码</span>
            </template>
          </van-field>
          <div class="center-vertical" style="padding: 15px">
            <van-button :disabled="loading" :loading="loading" round block type="info" :style="{ background: $store.state.themeInfo.themeColor, border: 'none' }">登录</van-button>
          </div>
        </van-form>

      </template>
     
      
  </div>
</template>

<script>

import { Toast } from 'vant'
export default {
  name: 'user',
  components: {},
  data() {
    return {
      containsMiceLink: false,
      refreshLoading: false,
      userInfoShow: false,
      miceLink: '',
      active: 1,
      tabActive: 0,
      phoneRules: /^(?:(?:\+|00)86)?1[3-9]\d{9}$/,
      /****** */
      loading: false, // 查询loading
      interval: null, 
      phoneErrorMsg: '',
      waitCode: false,
      sendCodeText: '获取验证码',
      searchSuccess: false, // 查询成功
      userInfo: {
        code: 'https://h5.sensemice.cn'
      }, // 参会人信息
      form: {
        phone: '',
        verifyCode: ''
      },
      arrangement: [], // 行程
      wzVipJourneys: [],
      vipUserInfo: {},
      shareType: 'normal',
      isSearch: false // 是否根据openid查询
    }
  },
  computed: {
    witchComp() {
      if (this.active === 1) {
        return 'myTicket'
      } else if (this.active === 2) {
        return 'Share'
      } else if (this.active === 3) {
        if (this.wzVipJourneys.length > 0) {
          return 'vipJourney'
        } else {
          return 'planList'
        }
      } else {
        return 'myTicket'
      }
    },
    auditStatus() {
      return this.$store.state.miceInfo.isAudit === 1 ? (this.userInfo.auditStatus ? this.userInfo.auditStatus === 1 : false) : true
    }
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1)
    },
    async onSubmit() {
      this.arrangement = []
      this.loading = true
      this.form.miceLink = this.$store.state.miceInfo.miceLink || this.miceLink
      this.form.openId = this.$store.state.userInfo.openid
      let result = await this.$http.post(this.$baseURL.index.loginByCode(), this.form, 1)
      this.loading = false
      if (result.code === 200) {
        console.log(result)
        Toast('登录成功!')
        this.$emit('loginSuccess', result.data)
      } else {
        Toast(result.message)
      }
    },
    async sendCode() { // 获取验证码
      if(this.phoneRules.test(this.form.phone)){
        this.phoneErrorMsg = ''
        this.timeOut()
        await this.$http.post(this.$baseURL.index.sendCode(), {
          phone: this.form.phone
        })
      } else{
        this.phoneErrorMsg = "请填写正确手机号"
      }
    },
    timeOut() { // 验证码倒计时
      let count = 60
      this.waitCode = true
      this.interval = setInterval(() => {
        this.sendCodeText = count + '秒后重试'
        if (count === 0) {
          clearInterval(this.interval)
          this.sendCodeText = '获取验证码'
          this.waitCode = false
        }
        count --
      },1000)
    },
    changeCode(code) {
      this.userInfo.code = code
    },
    switchType(index, type = '') {
      if (type === 'normal') {
        this.shareType = 'normal'
      } else if (type === 'vip') {
        this.shareType = 'vip'
      }
      this.active = index
    },

    clickShare() {
      this.active = 2
    }
  },
  mounted() {
    if (this.$route.params.miceLink) {
      this.miceLink = this.$route.params.miceLink
    }
  }
}
</script>

<style lang="less" scoped>
@themeColor: var(--themeColor);
/deep/.van-field{
  &::before{
    content: ''
  }
}
/deep/.van-field__label{
  color: #222;
  font-size: 13px;
  font-weight: bold;
  margin-right: 0.8rem;
}
/deep/.van-field__control{
  &::-webkit-input-placeholder{
    color: #999;
    font-size: 13px;
  }
}
/deep/.van-field__label{
  position: relative;
  color: #222;
  font-size: 13px;
  font-weight: bold;
  margin-right: 0.8rem;
  width: 70px;
  display: flex;
  justify-content: space-between;
}
.auditing-wraper{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 15px;
  font-size: 14px;
  color: #666;
  background: #fff;
  /deep/.van-divider{
    width: 100%;
    margin: 5px 0;
  }
  img{
    border-radius: 5px;
    object-fit: cover;
    height: 100px;
    width: 100px;
  }
  .text{
    width: 100%;
    padding: 5px 0;
    .fl-left{
      float: left
    }
    .fl-right{
      float: right;
      color: @themeColor
    }
  }
  .status{
    height: 30px;
    line-height: 30px;
  }
}
/deep/ .van-nav-bar__text,
/deep/ .van-nav-bar__arrow,
/deep/ .van-nav-bar__title{
  color: #fff;
}
/deep/.van-tabs__line{
  background: @themeColor;
}
/deep/ .van-pull-refresh {
  overflow: visible;
}
.van-tabs{
  z-index: 1;
}
.van-sticky{
  z-index: 2001;
}
.ticket-enter,
.ticket-leave-to{
  transform: translateY(50px);
  opacity: 0;
}
.ticket-enter-active,
.ticket-leave-active{
  transition: all .3s ease;
}
</style>